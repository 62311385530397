import { removeAllFromClass } from '../helpers/dom-helpers';

// DROPDOWNS
const tooltipTriggerClass = 'js-tooltip';
const tooltipTargetClass = 'js-tooltip-target';
const activeClass = 'is-active';

document.addEventListener('click', function (e) {
    const parentEl = e.target.closest(`.${tooltipTargetClass}`);

    if (e.target.matches('.' + tooltipTriggerClass) || parentEl) {
        let domElem = e.target;
        let target_id = domElem.getAttribute('data-target');
        let targetElem = document.getElementById(target_id);

        if (!parentEl) {
            if (domElem.classList.contains(activeClass)) {
                removeAllFromClass(tooltipTriggerClass, activeClass);
                removeAllFromClass(tooltipTargetClass, activeClass);
            } else {
                removeAllFromClass(tooltipTriggerClass, activeClass, domElem);
                removeAllFromClass(tooltipTargetClass, activeClass);
                domElem.classList.add(activeClass);
                targetElem.classList.add(activeClass);
            }
        }
    } else {
        removeAllFromClass(tooltipTriggerClass, activeClass);
        removeAllFromClass(tooltipTargetClass, activeClass);
    }
});

document.addEventListener('scroll', function (e) {
    removeAllFromClass(tooltipTriggerClass, activeClass);
    removeAllFromClass(tooltipTargetClass, activeClass);
});